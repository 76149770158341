import {createApp} from 'vue'
import Meta from 'vue-meta'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faChevronUp,
    faEnvelope,
    faPhoneAlt,
    faMobileAlt,
    faCircleNotch,
    faUser,
    faShieldVirus,
    faComment,
    faUserTie,
    faMicrophone,
    faEye,
    faEdit,
    faTrashAlt,
    faCheckCircle,
    faChevronRight,
    faChevronDown,
    faStickyNote,
} from '@fortawesome/free-solid-svg-icons'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-multiselect/dist/vue-multiselect.css'

import Adda from '@/adda.vue'
import router from '@/routes'
import {createPinia, PiniaVuePlugin} from 'pinia'
import VueCompat from '@vue/compat'
import {createHead} from '@unhead/vue'

const pinia = createPinia()
const app = createApp(Adda)
const head = createHead()

app.use(VueCompat)
app.use(pinia)
app.use(PiniaVuePlugin)
app.use(Meta)
app.use(VueCookies)
app.use(Toast, {position: 'top-center',
timeout: 5000})
app.use(VueGoodTablePlugin)
app.use(head)

library.add(
    faChevronUp,
    faEnvelope,
    faPhoneAlt,
    faMobileAlt,
    faCircleNotch,
    faUser,
    faShieldVirus,
    faComment,
    faUserTie, faMicrophone, faEye, faEdit, faTrashAlt, faChevronRight, faChevronDown, faCheckCircle, faStickyNote)
app.component('FontAwesomeIcon', FontAwesomeIcon)

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

app.use(router)

app.mount('#app')

