import apiClient from '@/utils/axios'
import VueCookies from 'vue-cookies'
import {apiEndPoints} from '@/utils/constant'
import axios from 'axios'

const {LOGIN_URL, USER_URL, LAWYER_URL, ADMIN_URL, REVIEWABLE_LAWYER_URL} = apiEndPoints

const apiBase = axios.create({
  baseURL: '',
})

const auth = {
    async userLogin(payload) {
      const response = await apiBase.post(LOGIN_URL, payload)
      return response
    },

    async userSignUp(userData) {
      const response = await apiClient.post(USER_URL, userData)
      return response
    },

    async lawyerSignUp(lawyerData) {
      const response = await apiClient.post(LAWYER_URL, lawyerData)
      return response
    },

    async getLawyerProfile() {
      const id = (VueCookies.get('auth').user_id)
      const response = await apiClient.get(`${LAWYER_URL}/${id}`)
      return response
    },

    async getLawyer(lawyerId) {
      const response = await apiClient.get(`${LAWYER_URL}/${lawyerId}`)
      return response
    },

    async getUnverifiedLawyer() {
      const response = await apiClient.get('/lawyers/unverified')
      return response
    },

    async getAllLawyer() {
      const response = await apiClient.get(LAWYER_URL)
      return response
    },

    async getReviewedLawyer() {
      const response = await apiClient.get('/lawyers/reviewed')
      return response
    },

    async getUser() {
      const id = (VueCookies.get('auth').user_id)
      const response = await apiClient.get(`${USER_URL}/${id}`)
      return response
    },

    async getAllUser() {
      const response = await apiClient.get(USER_URL)
      return response
    },

    async getUnconfirmedUsers() {
      const response = await apiClient.get('/unconfirmed_users')
      return response
    },

    async getUnconfirmedLawyers() {
      const response = await apiClient.get('/lawyers/unconfirmed')
      return response
    },

    async getRejectedLawyers() {
      const response = await apiClient.get('/lawyers/rejected')
      return response
    },

    async getAllAdmin() {
      const response = await apiClient.get(ADMIN_URL)
      return response
    },

    async postResetPassword(userData) {
      const response = await apiBase.post(`${USER_URL}/password`, userData)
      return response
    },

    async changePassword(userData) {
      const id = (VueCookies.get('auth').user_id)
      const response = await apiClient.patch(`${USER_URL}/${id}`, userData)
      return response
    },

    async updateLawyerProfile(lawyerData) {
      const id = (VueCookies.get('auth').user_id)
      const response = await apiClient.patch(`${LAWYER_URL}/${id}`, lawyerData)
      return response
    },

    async verifyLawyer(lawyerData, userId) {
      const response = await apiClient.patch(`${LAWYER_URL}/verify/${userId}`, lawyerData)
      return response
    },

    async rejectLawyer(lawyerData, userId) {
      const response = await apiClient.patch(`${LAWYER_URL}/reject/${userId}`, lawyerData)
      return response
    },
    async amendmentLawyer(lawyerData, lawyerId) {
      const response = await apiClient.put(`${LAWYER_URL}/${lawyerId}/amendment`, lawyerData)
      return response
    },

    async resendConfirmation(userData) {
      const response = await apiClient.post('/reconfirm', userData)
      return response
    },

    async getReviewableLawyers() {
      const response = await apiClient.get(REVIEWABLE_LAWYER_URL)
      return response
    },
  }

  export {auth}
