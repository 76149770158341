/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import VueCookies from 'vue-cookies'
import {constant} from '../utils/constant'
import {combineErrors} from './common'

const {API_BASE_URL} = constant

const apiClient: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
})

apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
    let token=''
    if (VueCookies.get('auth')) {
      token = VueCookies.get('auth').token
    }
    config.headers.Authorization = token
    return config
})

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    const errors = error.response.data.errors || error.response.data.error || 'Something Went Wrong'
    if (error.response.status === 422) {
      return Promise.reject(combineErrors(errors))
    }
    return Promise.reject(combineErrors(errors))
  },
)

export default apiClient
