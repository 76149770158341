export const constant: {[key: string]: string} = {
    API_BASE_URL: '/api/v1',
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const alphaSpaceRegex = /^[a-zA-Z ]*$/
export const numberRegex = /^[0-9]*$/
export const mobileNumberRegex = /^(?:\(?\+977\)?)?[9][6-9]\d{8}$/
export const phoneNumberRegex = /^(?:\(?\+977\)?)?[9][6-9]\d{8}$|[0][1][-]?[0-9]{7}$|[0][0-9]{2}[-]?[0-9]{6}$/

export const apiEndPoints: {[key: string]: string} = {
    TESTIMONIAL_URL: '/testimonial',
    QUESTION_URL: 'questions',
    LAWYER_URL: '/lawyers',
    USER_URL: '/users',
    COMMENT_URL: 'comments',
    ANSWER_URL: '/answers',
    ADMIN_URL: '/admins',
    LOGIN_URL: '/login',
    DISTRICT_URL: '/cities',
    PERMISSION_ROLE_URL: '/permissions_roles',
    PERMISSION_URL: '/permissions',
    ROLE_URL: '/roles',
    REPLIED_QUERIES_URL: '/replied_queries',
    REVIEWABLE_LAWYER_URL: '/lawyers/reviewable',
    UNANSWERED_QUESTIONS_URL: '/unreplied_queries',
    SERVICE_URL: '/services',
    SOLICITATION_URL: '/solicitations',
    SOLICITATION_NOTE_URL: '/solicitation_notes',
    SOLICITATION_AGENT_URL: '/agents',
}

export const errorMessage: {[key: string]: string} = {
    STH_WENT_WRONG: 'Something went wrong!',
    INTERNAL_SERVER_ERROR: 'Internal Server Error',
}

export const successMessage: {[key: string]: string} = {
    SUCCESS: 'Successful',
    SIGNUP_SUCCESS: 'Signed up successfully',
    FETCH_RECENTLY_ANSWERED_QUESTION_SUCCESS: 'Recently answered question fetched successfully.',
    FETCH_TESTIMONIAL_SUCCESS: 'Testimonial fetched successfully.',
    FETCH_RECENTLY_ASKED_QUESTION_SUCCESS: 'Recently asked question fetched successfully.',
    FETCH_LAWYER_SUCCESS: 'Lawyers fetched successfully.',
    FETCH_UNVERIFIED_LAWYER_SUCCESS: 'Unverified lawyer fetched successfully.',
    FETCH_UNCONFIRMED_LAWYER_SUCCESS: 'Unconfirmed lawyer fetched successfully.',
    DELETE_SUCCESS: 'Deleted successfully.',
    CREATE_TESTIMONIAL_SUCCESS: 'Testimonial created successfully',
    UPDATE_TESTIMONIAL_SUCCESS: 'Testimonial updated successfully',
    REPLY_QUESTION_SUCCESS: 'Replied to question successfully.',
    ADD_COMMENT_SUCCESS: 'Comment added successfully.',
    FORM_SUBMIT_SUCCESS: 'Form submitted successfully.',
    VERIFY_LAWYER_SUCCESS: 'Lawyer verified successfully.',
    REJECT_LAWYER_SUCCESS: 'Lawyer rejected successfully',
    RESEND_CONFIRMATION_SUCCESS: 'Confirmation email resend successfully.',
    CREATE_PERMISSION_SUCCESS: 'Permission created successfully.',
    DELETE_PERMISSION_SUCCESS: 'Permission deleted successfully.',
    CREATE_ROLE_SUCCESS: 'Role created successfully.',
    DELETE_ROLE_SUCCESS: 'Role deleted successfully.',
    DELETE_ROLES_PERMISSION_SUCCESS: 'Roles-Permission deleted successfully.',
    CREATE_ROLES_PERMISSION_SUCCESS: 'Roles-Permission created successfully.',
    CREATE_USER_SUCCESS: 'User created successfully.',
    CONFIRMATION_EMAIL_SENT: 'Confirmation Email has been sent successfully.',
    LAWYER_SIGNUP_SUCCESS: 'Lawyer created successfully.',
    THANK_INFO_MESSAGE: 'Thank you for registering. You will be notified once verified.',
    UPDATE_QUESTION_SUCCESS: 'Question updated successfully.',
    CREATE_REVIEW_SUCCESS: 'Review added successfully.',
    RESET_PASSWORD_LINK_SUCCESS: 'Reset link has been sent successfully. Please check your email.',
    CHANGE_PASSWORD_SUCCESS: 'Password changed successfully.',
    CREATE_QUESTION_SUCCESS: 'Question created successfully.',
    RESEND_CONFIRMATION_LINK_SUCCESS: 'Confirmation resend successful',
    LAWYER_UPDATE_SUCCESS: 'Lawyer Updated successfully.',
    CREATE_SUBSCRIPTION_SUCCESS: 'Subscription posted successfully.',
    LOGGED_IN_MESSAGE: 'You are logged In.',

    CREATE_SERVICE_SUCCESS: 'Service created successfully.',
    UPDATE_SERVICE_SUCCESS: 'Service updated successfully.',
    CREATE_SOLICITATION_SUCCESS: 'Solicitation created successfully.',
    UPDATE_SOLICITATION_SUCCESS: 'Solicitation updated successfully.',

    CREATE_SOLICITATION_NOTE_SUCCESS: 'Solicitation note created successfully.',
    UPDATE_SOLICITATION_NOTE_SUCCESS: 'Solicitation note updated successfully.',
    DELETE_SOLICITATION_NOTE_SUCCESS: 'Solicitation note deleted successfully.',

    CREATE_SOLICITATION_AGENT_SUCCESS: 'Solicitation agent created successfully.',
    UPDATE_SOLICITATION_AGENT_SUCCESS: 'Solicitation agent updated successfully.',
    DELETE_SOLICITATION_AGENT_SUCCESS: 'Solicitation agent deleted successfully.',

}

export const meta: {[key: string]: string} = {
    description: `What is MeroAdda? MeroAdda® is a web portal
    that facilitates free legal consultation in the form of
    Questions and Answers (Q&A) between service seekers and
    licensed Advocates of Nepal.`,
    keywords: `Professional Services, Free legal question answer,
    Company Registration and compliances, IRD Registration,
    Share Lagat, Company Amendment, Annual Reporting and Filing,
    Rental Agreements, Lease Agreement, Service Agreements,
    Contracts (personal and Professional), Design, Patent,
    Trademark Registration, Copyright, Intellectual Property,
    Deregistration, Liquidation, Social Welfare Council registration, Exim Code,
    Department Registration, Foreign Direct investment, Corporate
    Conversions, Law related Events and trainings, Legal Technology Company in Nepal,
    Tax, VAT, PAN, Lawyer, Advocate, Law, Nepal,
    Lawyer Near me, Legal Education, Literacy, Find a Lawyer,
    Legal Marketplace, Non Resident Nepali Services, NRN.`,
}
