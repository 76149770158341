import apiClient from '@/utils/axios'

const pagination = {
  async getPaginatedData(url) {
    const response = await apiClient.get(url)
    return response
},
}

export {pagination}
