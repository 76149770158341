import {pagination} from '@/api-services'
import {isArray} from '@vue/shared'

/**
 * It trims text to certain characters
 *
 * @param {string} text - Text that need to be trimmed
 * @param {number} length - Length to which text need to be trimmed
 * @return {string}
 */
export const textTrimer = (text: string, length: number): string => {
  if (text.length > length) {
    return text.slice(0, length) + '...'
  }
  return text
}

interface LinkHeader {
  [key: string]: string;
}

export const parseLinkHeader = (linkHeader: string): LinkHeader => {
  const links: LinkHeader = {}
  linkHeader.split(',').forEach((link) => {
    const match = link.match(/<(.+)>; rel="(.+)"/)
    if (match) {
      links[match[2]] = match[1]
    }
  })
  return links
}

/**

Handles pagination by retrieving paginated data from the given URL.
Handles all the pagination urls (first, next, previous and last page url).
@async
@param {string} Url - The URL to retrieve paginated data from.
@returns {Promise} A Promise object that resolves with the paginated data response from the server.
@example
const paginatedData = await paginationHandler("https://example.com/api/data?page=2");
*/

export const paginationHandler = async (Url: string) => {
  const response = await pagination.getPaginatedData(Url)
  return response
}


/**
 * Generates the full name by concatenating the first name, middle name, and last name.
 * If a middle name is provided, it will be included in the full name; otherwise, it will be excluded.
 *
 * @param {string} firstName - The first name.
 * @param {string} lastName - The last name.
 * @param {string | null} [middleName=null] - The middle name (optional).
 * @returns {string} The full name.
 */

export const fullName = (
  firstName: string,
  lastName: string,
  middleName: string | null = null,
): string => {
  if (middleName) return `${firstName} ${middleName} ${lastName}`
  return `${firstName} ${lastName}`
}

/**
 * Converts an ISO 8601 date-time string to a custom format.
 *
 * @param {string} isoString - The ISO 8601 date-time string to convert (e.g., "2023-05-27T16:16:28.404Z ").
 * @return {string} The date-time in the custom format (e.g., "Sep 27, 2023, 4:16 PM").
 */
export const convertISOTimeStampToDateTime = (isoString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const date = new Date(isoString)
  return date.toLocaleString('en-US', options)
}


interface Errors {
  [key: string]: Array<string>;
}


/**
 * Combines error messages from different sources into a single array of unique error messages.
 *
 * @param {Errors | string | string[]} errors - The input errors to be combined.
 *   It can be an object with error types and messages, a single string error message,
 *   or an array of string error messages.
 *
 * @return {string[]} An array containing unique error messages from the input.
 *   If the input is an object, the messages are combined and prefixed with their error type.
 *   If the input is a string or an array of strings, it is returned as is (with duplicates removed).
 *   If the input is undefined or null, a default error message is returned.
 */
export const combineErrors = (errors: Errors | string | string[]): string[] => {
  if (typeof errors === 'object') {
    const uniqueErrorsSet: Set<string> = new Set()

    if (isArray(errors)) {
      errors.map((val)=> uniqueErrorsSet.add(val))
    } else {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          const errorValue = errors[key]
          errorValue.map((val)=>uniqueErrorsSet.add(`${key} ${val}`))
        }
      }
    }

  return Array.from(uniqueErrorsSet)
  } else {
    return [errors]
  }
}

/**
 * Capitalizes the first letter of a given word.
 *
 * @param {string} word - The word to capitalize.
 * @return {string} The word with the first letter capitalized.
 */
export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase()
  + word.slice(1)
}
